import { mapGetters } from 'vuex'

export default {
  name: 'education',
  components: {
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      eduContents: [
        {
          id: '1',
          tabName: 'Curriculum Development',
          active: false,
          submenu: {
            image: 'curriculum-development',
            desc: 'Working together to create the newest technology-based curriculum for tackling today’s global challenges.',
            benefit: ['Industry-adaptive curriculum', 'Real-world experience via project or task', 'Widen teachers’ comprehension and technical skills', 'Deliver ready-to-work graduates']
          }
        },
        {
          id: '2',
          tabName: 'Kampus Merdeka',
          active: false,
          submenu: {
            image: 'kampus-merdeka',
            desc: 'Supporting the government’s “Freedom to Learn” program by giving students exposure to real-world experiences.',
            benefit: ['Student Internship', 'Teaching experiences', 'Independent study / projects']
          }
        },
        {
          id: '3',
          tabName: 'Digital Transformation',
          active: false,
          submenu: {
            image: 'digital-transformation',
            desc: 'Collaborating with education institutes to digitize their internal communication, teaching and administrative processes.',
            benefit: [
              'Maximizing digital process',
              'Providing technology that enriches the learning experience',
              'Enhacing technology for teachers, staff, and education personnel to support the “Freedom to Learn” program',
              'Simplified bureaucracy with technology'
            ]
          }
        }
      ],
      selectedTab: '',
      selectedContent: {
        desc: '',
        benefit: []
      }
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  created() {
    this.fillFirstContent()
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    imageName() {
      return this.selectedTab.replace(/\s/g, '-').toLowerCase()
    }
  },
  methods: {
    fillFirstContent() {
      this.selectedTab = this.eduContents[0].tabName
      this.selectedContent.desc = this.eduContents[0].submenu.desc
      this.selectedContent.benefit = this.eduContents[0].submenu.benefit
    },
    selectTab(content) {
      this.selectedTab = content.tabName
      this.selectedContent.desc = content.submenu.desc
      this.selectedContent.benefit = content.submenu.benefit
    },
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    }
  }
}
